
import { Component, Mixins } from 'vue-property-decorator';

import HeaderInfosMixin from '../header-infos';
import ConfigurationCollectionMixin from '@/mixins/data-operations/collection/configuration-collection-mixin';

import { storageToStorageConfs } from '@/store/modules/easy-firestore/storage-to-storage-confs';
import { STORAGE_TO_STORAGE_CONFIGURATIONS_ITEM } from '@/constants/router/routes-names';
import {
	ACTIVATED,
	CONF_CLIENT_VERSION,
	ENVIRONMENT,
	NB_FILENAME_TEMPLATES,
	ID,
} from '@/constants/data-operations/listing/header-items';

@Component
export default class StorageToStorageConfigurationsListingView extends Mixins(
	HeaderInfosMixin,
	ConfigurationCollectionMixin
) {
	moduleName: string = storageToStorageConfs.moduleName;
	overriddenColumns: string[] = ['id'];

	get routeName() {
		return STORAGE_TO_STORAGE_CONFIGURATIONS_ITEM;
	}

	get headers() {
		return [ENVIRONMENT, CONF_CLIENT_VERSION, ID, NB_FILENAME_TEMPLATES, ACTIVATED];
	}
}
